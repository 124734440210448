@keyframes arrowForward{
    0%{
        
        // background: linear-gradient(to right,  #ef4444 0%,black 0%,);
        // color: #000000;
        // transform: translateX(-50%);
        left: -100px;
        opacity: 0;
    }
    // 99%{
    //     color: #000000;
    //     background: linear-gradient(to right,  #ef4444 100%,black 100%,);
    // }
    // 99%{
    //     // transform: scale(1,1);
    //     opacity: 1;
    // }
    100%{
        // background: linear-gradient(to right,  #ef4444 100%,black 100%,);
        // color: #ef4444;
        // transform: scale(2,10);
        // transform: translateX(0%);
        left: 0px;

        opacity: 1;
    }
}
.arrowForward{
    animation-name: arrowForward;
    animation-duration: 800ms;
    animation-timing-function: linear;
    // animation-direction: alternate;
    animation-fill-mode: forwards;
position: relative;
    // animation: arrowForward 1000ms  forwards;
    
}