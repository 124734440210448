@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes arrowForward {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
.arrowForward {
  animation-name: arrowForward;
  animation-duration: 800ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  position: relative;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

@layer utilities {}
.max-w {
  max-width: 120rem;
}

@font-face {
  font-family: "BasierSquareMedium";
  src: url(./fonts/Basier\ Square\ Medium.otf);
}
ul {
  list-style-type: disc;
}
ul li::marker {
  color: red;
}

.BasierSquareMedium {
  font-family: BasierSquareMedium;
}

@font-face {
  font-family: "BasierSquareRegular";
  src: url(./fonts/Basier\ Square\ Regular.otf);
}
.BasierSquareRegular {
  font-family: BasierSquareRegular;
}

@font-face {
  font-family: "BasierSquareSemibold";
  src: url(./fonts/Basier\ Square\ SemiBold.otf);
}
.BasierSquareSemibold {
  font-family: BasierSquareSemibold;
}

@font-face {
  font-family: "DecimalBook";
  src: url(./fonts/Decimal-Book.otf);
}
.DecimalBook {
  font-family: DecimalBook;
}

@font-face {
  font-family: "DecimalLight";
  src: url(./fonts/Decimal-Light.otf);
}
.DecimalLight {
  font-family: DecimalLight;
}

@font-face {
  font-family: "DecimalMedium";
  src: url(./fonts/Decimal-Medium.otf);
}
.DecimalMedium {
  font-family: DecimalMedium;
}

@font-face {
  font-family: "DecimalXlight";
  src: url(./fonts/Decimal-XLight.otf);
}
.DecimalXlight {
  font-family: DecimalXlight;
}

@font-face {
  font-family: "National2CompresedXbold";
  src: url(./fonts/National\ 2\ Compressed\ XBold.otf);
}
.National2CompresedXbold {
  font-family: National2CompresedXbold;
}

@font-face {
  font-family: "AdobeFnt23";
  src: url(./fonts/AdobeFnt23.lst);
}
.AdobeFnt23 {
  font-family: AdobeFnt23;
}

@font-face {
  font-family: "AdobeFnt24";
  src: url(./fonts/AdobeFnt24.lst);
}
.AdobeFnt24 {
  font-family: AdobeFnt24;
}

.text-liniarGra {
  background: -webkit-linear-gradient(0deg, #F71725, #4B061F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bb {
  border: 2px solid red;
}

.padding-l, .padding-x {
  @apply pl-8 sm:pl-16 xl:pl-[9.4375rem];
}

.padding-r, .padding-x {
  @apply pr-8 sm:pr-16 xl:pr-[9.4375rem];
}

.banner1 {
  @apply h-full sm:min-h-[37.5rem] sm:h-fit;
}
.banner1 .left {
  @apply xl:w-1/2 sm:w-1/3 hidden sm:block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner1 .home {
  background-image: url(../images/hwmidjourney_three_quarter_angle_view_shot_of_a_happy_couple_si_0a22f54a-75be-4dd4-a53f-4777cda3f119.jpg);
}
.banner1 .about {
  background-image: url(../images/shutterstock_2293756953.jpg);
}
.banner1 .right {
  @apply flex pt-8 py-6 lg:py-14 2xl:py-6 overflow-hidden sm:justify-center relative flex-col xl:w-1/2 sm:w-2/3 w-full;
}

.banner2 {
  @apply xl:mt-[7.9rem] sm:mt-[6rem] mt-[4rem];
}
.banner2 .services {
  @apply xl:mt-[3.75rem] mt-[2.75rem] grid sm:grid-cols-2 xl:grid-cols-4;
}

.banner3 {
  position: relative;
  @apply flex items-center flex-col justify-center;
}
.banner3::before {
  position: absolute;
  content: "";
  top: 0;
  z-index: 2;
  @apply h-[15rem] w-[15rem] left-[2rem] md:h-[21rem] md:w-[21rem] md:left-[5rem];
  opacity: 0.1;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/71ZEttVe5hL._AC_UF894_1000_QL80_.png);
  background-size: cover;
}

.banner7 {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/Credo\ Web\ Design.png);
  background-size: cover;
}

.rec-carousel-item > div {
  @apply h-full;
}

.rec-swipable {
  @apply gap-3;
}

.carosol button {
  box-sizing: border-box;
  transition: all 0.3s ease 0s;
  font-size: 1.6em;
  /* background-color: rgba(103, 58, 183, 0.1); */
  /* color: rgb(51, 51, 51); */
  /* box-shadow: rgb(51, 51, 51) 0px 0px 2px 0px; */
  border-radius: 50%;
  border: none;
  padding: 0px;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  background-color: transparent !important;
  box-shadow: none !important;
  /* width: 50px; */
  /* height: 50px; */
  /* min-width: 50px; */
  line-height: 50px;
  align-self: center;
  cursor: pointer;
  outline: none;
}

.card5 .left {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.hoverScale {
  @apply transition-all;
}
.hoverScale:hover {
  @apply scale-110 shadow-md;
}

input:focus {
  outline: none;
}/*# sourceMappingURL=style.css.map */